import { createSlice } from '@reduxjs/toolkit'

import { createActions, initialStateTemplate, reducers } from '.reducers/libraries/filters.template'

const initialState = {
	companyId: window.sessionStorage.getItem('libCopy_currentCompanyID'),
	name: '',
	qcStatus: null,
	...initialStateTemplate,
}

const libCopyFilters = createSlice({
	name: 'LIB/COPY/FILTERS',
	initialState,
	reducers: {
		...reducers,
		setQCStatus(state, { payload: qcStatus }) {
			state.qcStatus = qcStatus
		},

	},
})

export const {
	setCurrentPage,
	setIsOpen,
	setPerPage,
	setOrder,
	setSortBy,
	resetLastUpdate,
	setCompanyId,
	setPublicLink,
	setTagFilter,
	setTagsFilter,
	setKeywordsFilter,
	resetTagsFilter,
	setInitialState,
	toggleFavorites,
	toggleAllFavorites,
	setSearchTerm,
	setBrandedFilter,
	clearTags,
	setShowUntagged,
	setUntaggedCategories,
	setShowUntaggedDeliveryNumbers,
} = createActions(libCopyFilters.name)

export const { setDeliveryNumbers, setQCStatus } = libCopyFilters.actions

export default libCopyFilters.reducer
