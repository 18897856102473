import { createSlice } from '@reduxjs/toolkit'

import {
	createActions,
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/filters.template'

const initialState = {
	companyId: window.sessionStorage.getItem('libImage_currentCompanyID'),
	name: '',
	...initialStateTemplate,
}

const libImagesFilters = createSlice({
	name: 'LIB/IMAGES/FILTERS',
	initialState,
	reducers: {
		...reducers,
		setName(state, { payload: name }) {
			state.name = name
		},
		setCity(state, { payload: city }) {
			state.city = city
		},
	},
})

export const {
	setCurrentPage,
	setIsOpen,
	setPerPage,
	setOrder,
	setSortBy,
	resetLastUpdate,
	setCompanyId,
	setTagFilter,
	setPublicLink,
	setTagsFilter,
	setKeywordsFilter,
	resetTagsFilter,
	setInitialState,
	toggleFavorites,
	setSearchTerm,
	unsetTagFilters,
	toggleAllFavorites,
	setClientProvidedFilter,
	setBrandedFilter,
	clearTags,
} = createActions(libImagesFilters.name)

export const { setName, setCity } = libImagesFilters.actions

export default libImagesFilters.reducer
