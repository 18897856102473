import { createSlice } from '@reduxjs/toolkit'

import {
	initialStateTemplate,
	reducers,
} from '.reducers/libraries/filters.template'

const initialState = {
	...initialStateTemplate,
	actorsIds: [],
	actorTags: [],
	searchTerm: '',
	before: '',
	videoPosition: '',
	perPage: 50,
	currentPage: 1,
	shootDateRange: [null, null],
	is_disabled: 'false',
	period: null,
	favorites: false,
	allFavorites: false,
	lastUpdate: 0,
}

const footageFilters = createSlice({
	name: 'FOOTAGE.FILTERS',
	initialState,
	reducers: {
		...reducers,
		setSearchTerm(state, { payload: searchTerm }) {
			state.searchTerm = searchTerm
		},

		setBefore(state, { payload: before }) {
			state.before = before
		},

		resetTagsFilter(state, { payload: tagToRemove }) {
			if (Array.isArray(tagToRemove)) {
				state.tags = state.tags.filter((tag) => !tagToRemove.includes(tag))
			}
		},

		setShootDateRange(state, { payload: range }) {
			state.shootDateRange = range
			state.currentPage = 1
		},

		setPeriod(state, { payload: period }) {
			state.period = period
		},

		setVideoOrientation(state, { payload: type }) {
			state.videoPosition = type
			state.currentPage = 1
		},

		toggleFavorites(state, { payload: uid }) {
			state.favorites = state.favorites ? false : uid
		},

		toggleAllFavorites(state) {
			state.allFavorites = state.allFavorites ? false : true
		},

		resetLastUpdate(state) {
			state.lastUpdate = Date.now()
		},

		toggleActorTag(state, { payload: actorTag }) {
			if (state.actorTags.find((tag) => tag === actorTag)) {
				state.actorTags = state.actorTags.filter((tag) => tag !== actorTag)
			} else {
				state.actorTags.push(actorTag)
			}
			state.currentPage = 1
		},

		clearAll(state) {
			return {
				...initialState,
				isOpen: state.isOpen,
			}
		},
	},
})

export const {
	setActorsIds,
	toggleActorTag,
	setIsOpen,
	setOrder,
	setSortBy,
	setPerPage,
	setSearchTerm,
	setCurrentPage,
	setCompanyId,
	setBefore,
	setFilter,
	setShootDateRange,
	setTagFilter,
	setTagsFilter,
	setKeywordsFilter,
	setPublicLink,
	setUniqueTagPerCategory,
	setPeriod,
	setDurationTagsFilter,
	toggleFavorites,
	toggleAllFavorites,
	setShowUntagged,
	setShowUntaggedDeliveryNumbers,
	setUntaggedCategories,
	resetLastUpdate,
	clearAll,
	resetTagsFilter,
	setVideoOrientation,
	setInitialState,
	setClientProvidedFilter,
	setBrandedFilter,
	setDeliveryNumbers,
	clearTags,
} = footageFilters.actions

export default footageFilters.reducer
