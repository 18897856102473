import { createSlice } from '@reduxjs/toolkit'

import { initialStateTemplate, reducers } from '.reducers/libraries/filters.template'

const initialState = {
	...initialStateTemplate,
	actorIds: [],
	actorTags: [],
	isOpen: false,
	order: 'desc',
	sortBy: '',
	before: '',
	videoPosition: '',
	perPage: 50,
	searchTerm: '',
	currentPage: 1,
	tags: [],
	companyId: window.sessionStorage.getItem('libAd_currentCompanyID'),
	period: 'all_time',
	favorites: false,
	allFavorites: false,
	justPhotos: false,
	deliveryNumbers: [],
	lastUpdate: 0,
	durationMin: 0,
	durationMax: null,
}

const libadsFilters = createSlice({
	name: 'LIB/ADS/FILTERS',
	initialState,
	reducers: {
		...reducers,
		setSearchTerm(state, { payload: searchTerm }) {
			state.searchTerm = searchTerm
		},

		setBefore(state, { payload: before }) {
			state.before = before
		},

		setShowJustPhotos(state, { payload: justPhotos }) {
			state.justPhotos = justPhotos
			state.currentPage = 1
		},

		setVideoOrientation(state, { payload: type }) {
			state.videoPosition = type
			state.currentPage = 1
		},

		setDurationTagsFilter(state, { payload: tags }) {
			const { durationTagsToAdd, durationTags } = tags
			if (Array.isArray(durationTagsToAdd) && Array.isArray(durationTags)) {
				const tagsToremove = durationTags
					.filter(({ category }) => category === 'Duration')
					.filter(({ id }) => !durationTagsToAdd.includes(id))
					.map(({ id }) => id)
				state.tags = [
					...new Set([...state.tags.filter((tagId) => !tagsToremove.includes(tagId)).concat(durationTagsToAdd)]),
				]
				state.currentPage = 1
			}
		},

		setPeriod(state, { payload: period }) {
			state.period = period
		},

		setDuration(state, { payload: duration }) {
			state.durationMax = duration?.max
			state.durationMin = duration?.min
			state.currentPage = 1
		},

		toggleActorTag(state, { payload: actorTag }) {
			if (state.actorTags.find((tag) => tag === actorTag)) {
				state.actorTags = state.actorTags.filter((tag) => tag !== actorTag)
			} else {
				state.actorTags.push(actorTag)
			}
			state.currentPage = 1
		},

		clearAll(state) {
			state.actorsIds = []
			state.deliveryNumbers = []
			state.tags = []
			state.ids = []
			state.keywords = []
			state.publicLinks = []
			state.actorTags = []
			state.favorites = false
			state.showUntagged = false
			state.untaggedCategories = []
			state.favorites = false
			state.allFavorites = false
			state.justPhotos = false
			state.videoPosition = ''
			state.durationMin = 0
			state.durationMax = null
		},
	},
})

export const {
	setIsOpen,
	setOrder,
	setSortBy,
	setPerPage,
	setSearchTerm,
	setCurrentPage,
	setCompanyId,
	setBefore,
	setFilter,
	setTagFilter,
	setTagsFilter,
	setKeywordsFilter,
	setPublicLink,
	setDurationTagsFilter,
	setUniqueTagPerCategory,
	setPeriod,
	toggleFavorites,
	toggleAllFavorites,
	setShowJustPhotos,
	setShowUntagged,
	setShowUntaggedDeliveryNumbers,
	setUntaggedCategories,
	resetLastUpdate,
	setVideoOrientation,
	clearAll,
	setInitialState,
	setDuration,
	setDeliveryNumbers,
	setActorsIds,
	toggleActorTag,
	clearTags,
} = libadsFilters.actions

export default libadsFilters.reducer
